import { Page } from "components/Page"
import { useMedia } from "hooks/useMedia"
import { InvestmentRow } from "./InvestmentRow"
import { MobileProductList } from "./MobileProductList"
import { ProductListTable } from "./ProductListTable"
import { Warning } from "./Warning"

export const DashboardPage = () => {
  const { isMobile } = useMedia()
  return (
    <Page withGreeting title="Your Investment">
      <InvestmentRow>Investment row</InvestmentRow>
      <Warning />
      {isMobile ? <MobileProductList /> : <ProductListTable />}
    </Page>
  )
}
