import { Box } from "components/Box"
import { Text } from "components/Text"
import { CardWrapper } from "../Card/styled"
import { AltItem } from "./styled"

export const HodlCard = () => (
  <CardWrapper primary p={30}>
    <Text color="white" fs={20} mb={25} light>
      HODL index
    </Text>
    <Text color="white" fs={13} mb={5} light opacity={0.6}>
      Amount
    </Text>
    <Text color="white" fs={39} mb={20} light lh={50}>
      €16,000
    </Text>
    <Text color="white" fs={13} light opacity={0.6} mb={10}>
      Strategy
    </Text>
    <Box flex wrap={1}>
      <AltItem underline color="white" fs={16} lh={30}>
        ALT10 - €2,000
      </AltItem>
      <AltItem underline color="white" fs={16} lh={30}>
        ALT10 - €2,000
      </AltItem>
      <AltItem underline color="white" fs={16} lh={30}>
        ALT10 - €2,000
      </AltItem>
      <AltItem underline color="white" fs={16} lh={30}>
        ALT10 - €2,000
      </AltItem>
      <AltItem underline color="white" fs={16} lh={30}>
        ALT10 - €2,000
      </AltItem>
      <AltItem underline color="white" fs={16} lh={30}>
        ALT10 - €2,000
      </AltItem>
      <AltItem underline color="white" fs={16} lh={30}>
        ALT10 - €2,000
      </AltItem>
      <AltItem underline color="white" fs={16} lh={30}>
        ALT10 - €2,000
      </AltItem>
    </Box>
    <AltItem underline color="white" fs={16} lh={30}>
      +6 more
    </AltItem>
  </CardWrapper>
)
