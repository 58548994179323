import { useLocation } from 'react-router-dom'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { ChangelogTableWrapper } from './styled'
import axios from 'axios'
import { useEffect, useState } from 'react'
import moment from 'moment'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const Withdrawals = (props) => {
  const response = props.response;
  const [withdrawals, setWithdrawals] = useState(null)

  useEffect(async () => {
    const allWithdrawals = response.data.events.withdrawals
    var valuesWithdrawals = Object.entries(allWithdrawals).map((record) => {
      return { date: record[0], value: record[1] }
    })
    if (valuesWithdrawals && valuesWithdrawals.length > 0) {
      setWithdrawals(valuesWithdrawals)
    }
  }, [])

  if (withdrawals) {
    return (
      <>
        <Text fs={20} mb={15} lh={30}>
          Withdrawals
        </Text>
        <ChangelogTableWrapper>
          <Table
            headers={['Date', 'Value']}
            data={withdrawals}
            config={{
              columns: [
                {
                  label: 'name',
                  path: (x) => <>{x.date}</>,
                },
                {
                  label: 'percentage',
                  path: (x) => (
                    <>
                      {x.value > 0 ? -x.value : x.value}{' '}
                      {x.value !== 'full withdrawal' && '$'}
                    </>
                  ),
                },
              ],
            }}
          />
        </ChangelogTableWrapper>
      </>
    )
  } else {
    return <></>
  }
}
