import { Notifications, Person } from "@material-ui/icons"
import { Icon } from "components/Icon"
import { IconsWrapper } from "./styled"

export const UserIcons = () => (
  <IconsWrapper flex>
    <Icon color="black" w={26} h={26}>
      <Notifications />
    </Icon>
    <Icon color="black" w={26} h={26} ml={30}>
      <Person />
    </Icon>
  </IconsWrapper>
)
