import { Fragment } from "react";
import { ExpandBox } from "../../../components/ExpandBox";
import { Table } from "../../../components/Table";
import { TableWrapper } from "./styled";

export const Rebalances = () => {
  return (
    <ExpandBox title="Rebalances" mt="30px" mb="15px">
      <TableWrapper>
        <Table
          headers={["Date"]}
          data={[{ date: "2021-09-10" }, { date: "2022-04-02" }, { date: "2022-12-22" }, { date: "2023-04-01" }, { date: "2023-10-23" }, { date: "2024-02-08" }]}
          config={{
            columns: [
              {
                label: "date",
                path: (row) => <Fragment>{row.date}</Fragment>
              }
            ]
          }}
        />
      </TableWrapper>
    </ExpandBox>
  );
};
