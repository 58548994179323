import { Box } from "components/Box"
import { Text } from "components/Text"
import { GraphTextWrapper } from "pages/DashboardPage/InvestmentRow/Card/styled"
import { PieChart, Pie, Cell } from "recharts"
import { theme } from "theme"

const getData = (total, filled) =>
  Array(filled)
    .fill({ value: 1, isFilled: true })
    .concat(Array(total - filled).fill({ value: 1, isFilled: false }))

export const AffiliateGraph = ({ filled, total }) => {
  const newData = getData(total, filled)
  return (
    <>
      <Box flex acenter justify="center" relative mt={40} mb={20}>
        <PieChart width={80} height={80} position="center">
          <Pie
            data={newData}
            dataKey="value"
            innerRadius="95%"
            outerRadius="100%"
            fill={theme.colors.lightGrey}
            startAngle={-270}
            endAngle={90}
            paddingAngle={5}
            blendStroke
            counte
          >
            {newData.map(x => (
              <Cell
                key="test"
                fill={x.isFilled ? theme.colors.primary : theme.colors.white}
              />
            ))}
          </Pie>
        </PieChart>
        <GraphTextWrapper flex acenter column>
          <Text color="primary" fs={20}>
            {filled}
          </Text>
        </GraphTextWrapper>
      </Box>
    </>
  )
}
