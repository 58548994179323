import { Box } from "components/Box"
import { Text } from "components/Text"
import { urls } from "pages/urls"
import { Navigation } from "../Navigation"
import { SidebarWrapper } from "./styled"

export const Sidebar = ({ customSidebar }) => (
  <SidebarWrapper acenter flex column pt={50} pr={30} pl={30}>
    <Box flex mb={customSidebar ? 10 : 150} to={urls.portfolio.url}>
      <div>
        <Text fs={20} uppercase bold ls={10}>
          Crypton
        </Text>
        <Text fs={10} uppercase ls={5}>
          Digital
        </Text>
      </div>
    </Box>
    {customSidebar || <Navigation />}
  </SidebarWrapper>
)
