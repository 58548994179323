import { Box } from "components/Box"
import { Text } from "components/Text"
import { AffiliateGraph } from "../AffiliateGraph"

export const Backoffice = () => (
  <>
    <AffiliateGraph total={8} filled={2} />
    <Text fs={20} mt={20} mb={10} light tAlign="center">
      Backoffice
    </Text>
    <Box flex spread mt={15}>
      <Box>
        <Text>Sold Backoffice</Text>
      </Box>
      <Box flex>
        <Text color="primary">15</Text>
        <Text>/50</Text>
      </Box>
    </Box>
    <Box flex spread mt={10}>
      <Box>
        <Text>Progress</Text>
      </Box>
      <Box flex>
        <Text>25%</Text>
      </Box>
    </Box>
  </>
)
