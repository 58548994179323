import { Button } from "components/Button"
import { Text } from "components/Text"
import { StyledTable, StyledTh, StyledTd } from "./styled"

const items = [1, 2, 3, 4, 5, 6, 7, 8]

export const ProductListTable = () => (
  <StyledTable>
    <thead>
      <tr>
        <StyledTh fs={20} lh={30}>
          Available Products
        </StyledTh>
        <StyledTh fs={13} lh={20} color="grey">
          Min. investment
        </StyledTh>
        <StyledTh fs={13} lh={20} color="grey">
          Period (years)
        </StyledTh>
      </tr>
    </thead>
    <tbody>
      {items.map((x, xi) => (
        <tr key={xi}>
          <StyledTd>Collateral</StyledTd>
          <StyledTd>€16,000</StyledTd>
          <StyledTd>2</StyledTd>
          <StyledTd>
            <Text color="primary">Read more </Text>
          </StyledTd>
          <StyledTd tAlign="right">
            <Button text="Buy now" />
          </StyledTd>
        </tr>
      ))}
    </tbody>
  </StyledTable>
)
