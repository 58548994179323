import { Box } from "components/Box"
import styled from "styled-components/macro"
import { theme } from "theme"

export const CardWrapper = styled(Box)`
  width: 286px;
  height: 370px;
  flex-shrink: 0;
  background: ${p => (p.primary ? theme.colors.primary : theme.colors.white)};
  border-radius: 20px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
  margin-right: 20px;
  ${theme.mq.desktop} {
    margin-right: 30px;
  }

  :last-of-type {
    margin-right: 0;
  }
`

export const GraphTextWrapper = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
