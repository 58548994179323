import { MNavWrapper, NavItem, DNavWrapper } from "./styled"
import { Home, Update, Receipt, People, Image } from "@material-ui/icons"
import { Icon } from "components/Icon"
import { Text } from "components/Text"
import { Box } from "components/Box"
import { urls } from "pages/urls"
import { useMedia } from "hooks/useMedia"

const getNavItems = () => [
  { name: "Dashboard", icon: <Home />, url: urls.dashboard.url },
  { name: "Orders History", icon: <Update />, url: urls.orders.url },
  { name: "Legals", icon: <Receipt />, url: urls.legals.url },
  { name: "Affiliate", icon: <People />, url: urls.affiliate.url },
  { name: "Marketing Materials", icon: <Image />, url: urls.marketing.url },
]

export const Navigation = () => {
  const { isDesktop } = useMedia()

  const nav = getNavItems().map(x => {
    const active = window.location.pathname === x.url
    return (
      <NavItem active={active ? 1 : 0} to={x.url} key={x.url}>
        <Box flex acenter left>
          <Icon mr={20} color={active ? "primary" : "lightGrey"}>
            {x.icon}
          </Icon>
          <Text>{x.name}</Text>
        </Box>
      </NavItem>
    )
  })
  return isDesktop ? (
    <DNavWrapper>{nav}</DNavWrapper>
  ) : (
    <MNavWrapper acenter justify="center">
      {nav}
    </MNavWrapper>
  )
}
