import { Card } from "./Card"
import { RowWrapper } from "./styled"
import { HodlCard } from "./HodlCard"
import { Box } from "components/Box"

export const InvestmentRow = () => (
  <RowWrapper flex pb={10}>
    <Box flex p={20}>
      <HodlCard />
      <Card title="Collateral 01" />
      <Card title="Collateral 02" />
    </Box>
  </RowWrapper>
)
