import { Box } from "components/Box"
import styled from "styled-components/macro"
import { theme } from "theme"

export const RowWrapper = styled(Box)`
  overflow-x: auto;
  margin: 0 -20px;

  ${theme.mq.desktop} {
    overflow-x: hidden;
  }
`
