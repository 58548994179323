import { Box } from 'components/Box'
import { Text } from 'components/Text'
import { useMedia } from 'hooks/useMedia'
import React from 'react'
import {
  Line,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  AreaChart,
  ResponsiveContainer,
} from 'recharts'
import { HoverItem } from './HoverItem'
import * as R from 'ramda'

export const PortfolioChart = ({ data, xAxis, mobile, handleClick }) => {
  const { isMobile } = useMedia()

  return (
    <>
      <ResponsiveContainer width={'100%'} height={isMobile ? 150 : 350}>
        <AreaChart
          // onClick={(e) => handleClick(e?.activePayload[0]?.payload?.name)}
          width={1100}
          height={350}
          data={data}
          stackOffset='sign'
          margin={{
            top: 5,
            right: mobile ? 0 : 30,
            left: mobile ? 0 : 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#45B55B' stopOpacity={0.6} />
              <stop offset='95%' stopColor='#45B55B' stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} />
          {mobile ? null : (
            <YAxis
              axisLine={false}
              padding={{ right: 100 }}
              minTickGap={40}
              tick={{ stroke: '#f2F2F2', strokeWidth: 0.8 }}
              tickMargin={20}
            />
          )}
          <Tooltip
            content={(tooltipdata) => {
              const d = R.path(['payload'], tooltipdata)
              if (!d[0]) return null
              return <HoverItem data={d[0].payload} />
            }}
          />
          <Line
            type='linear'
            dataKey='pv'
            stroke='#45B55B'
            activeDot={{ fill: '#000', r: 8 }}
            strokeWidth={3}
          />
          <Area
            type='linear'
            dataKey='pv'
            activeDot={{ fill: '#000', r: 8 }}
            strokeWidth={3}
            stroke='#45B55B'
            fillOpacity={1}
            fill='url(#colorUv)'
          />
        </AreaChart>
      </ResponsiveContainer>
      <Box
        flex
        spread
        mr={mobile ? 0 : 30}
        ml={mobile ? 0 : 80}
        mt={mobile ? 5 : 10}
      >
        {xAxis.map((x) => (
          <Text color='grey' fs={13} lh={30} key={x}>
            {x}
          </Text>
        ))}
      </Box>
    </>
  )
}
