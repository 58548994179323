import React from 'react';
import { ContentWrapper, ErrorMessageWrapper, StatusWrapper, Wrapper } from './styled';
import { Header } from 'components/PageWithoutSidebar/Header';

const ERROR_STATUS_CODE = 404
const ERROR_MESSAGE =  "PROVIDED USER'S HASH DOES NOT HAVE DATA IN `PORTFOLIO` TABLE BY USERID"

const ErrorMessage = ({ errorMessage, errorStatus }) => {

    if (errorStatus === ERROR_STATUS_CODE && errorMessage === ERROR_MESSAGE ) {
        return (
            <>
                <Header />
                <Wrapper>
                    <ContentWrapper biggerFontSize>Portfolio will be available <b>soon</b> &#128578;</ContentWrapper>
                </Wrapper>
            </>
        )
    }

    return (
        <>
            <Header />
            <Wrapper>
                <ContentWrapper biggerFontSize>Your Portfolio will be ready <b>soon</b> &#128578;</ContentWrapper>
                <ContentWrapper>For any questions, please contact our <a href="https://crypton.digital/sk/contact" style={{textDecoration:'underline'}}>support.</a></ContentWrapper>
            </Wrapper>
        </>
    );
};

export default ErrorMessage;