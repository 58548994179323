import { Icon } from "components/Icon"
import { useState } from "react"
import { Box } from "../../Box"
import { Navigation } from "../Navigation"
import { MHWrapper } from "./styled"
import { NotificationsActive, Person, Menu, Close } from "@material-ui/icons"
import { Text } from "components/Text"

export const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <MHWrapper acenter spread>
      <Box flex ml={20}>
        <div>
          <Text fs={14} uppercase bold ls={8}>
            Crypton
          </Text>
          <Text fs={8} uppercase ls={5}>
            Digital
          </Text>
        </div>
      </Box>
      <Box flex mr={10}>
        <Icon m={10} w={22} h={22}>
          <NotificationsActive />
        </Icon>
        <Icon m={10} w={22} h={22}>
          <Person />
        </Icon>
        <Icon m={10} onClick={() => setIsOpen(!isOpen)} w={22} h={22}>
          {isOpen ? <Close /> : <Menu />}
        </Icon>
      </Box>
      {isOpen && <Navigation />}
    </MHWrapper>
  )
}
