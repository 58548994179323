import { DashboardPage } from "./DashboardPage"
import { OrdersPage } from "./OrdersPage"
import { LegalsPage } from "./LegalsPage"
import { AffiliatePage } from "./AffiliatePage"
import { MarketingPage } from "./MarketingPage"
import { PortfolioPage } from "./PortfolioPage"

export const urls = {
  dashboard: {
    component: DashboardPage,
    path: "/dashboard",
    url: "/dashboard",
    isExact: true,
  },
  orders: {
    component: OrdersPage,
    path: "/orders",
    url: "/orders",
  },
  legals: {
    component: LegalsPage,
    path: "/legals",
    url: "/legals",
  },
  affiliate: {
    component: AffiliatePage,
    path: "/affiliate",
    url: "/affiliate",
  },
  marketing: {
    component: MarketingPage,
    path: "/marketing",
    url: "/marketing",
  },
  portfolio: {
    component: PortfolioPage,
    path: "/",
    url: "/",
  },
}
