import { flex } from "common/styles"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components/macro"
import { theme } from "theme"

export const MNavWrapper = styled.div`
  background: ${theme.colors.black};
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: calc(100vh - 80px);
  flex-grow: auto;
  top: 100%;
  left: 0;
  z-index: 1;
  margin: auto;
  ${flex};
`
export const DNavWrapper = styled.div`
  background: ${theme.colors.black};
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;

  width: 100%;
  ${flex};
`

export const NavItem = styled(Link)`
  color: ${theme.colors.lightGrey};
  margin-bottom: 20px;
  padding: 20px 30px;
  min-width: 240px;
  border-radius: 5px;
  text-transform: capitalize;
  ${p =>
    p.active &&
    css`
      background: rgba(${theme.rgbColors.white}, 0.1);
      color: ${theme.colors.white};
    `};
`
