import { flex } from "common/styles"
import styled from "styled-components/macro"
import { theme } from "theme"

export const MHWrapper = styled.div`
  background: ${theme.colors.black};
  color: ${theme.colors.white};
  display: flex;
  position: relative;
  height: 80px;
  flex-shrink: 0;
  ${flex};
`
