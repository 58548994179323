import { Box } from "components/Box"
import { Text } from "components/Text"

export const Greeting = ({ user }) => (
  <Box flex mb={20}>
    <Text fs={13} mr={5} color="mediumGrey">
      Hello
    </Text>
    <Text fs={13} bold>
      {user.firstName} {user.lastName}
    </Text>
  </Box>
)
