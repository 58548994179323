import { Box } from "components/Box"
import { Text } from "components/Text"
import { PieChart, Pie, Cell } from "recharts"
import { theme } from "theme"
import { CardWrapper, GraphTextWrapper } from "./styled"
const data = [
  { id: "1", name: "L1", value: 65 },
  { id: "2", name: "L2", value: 35 },
]
export const Card = p => {
  return (
    <CardWrapper {...p} p={30}>
      <Text fs={20} lh={30} light>
        {p.title}
      </Text>
      <Box flex acenter justify="center" relative mt={20} mb={30}>
        <PieChart width={180} height={180} position="center">
          <Pie
            data={data}
            dataKey="value"
            innerRadius="99%"
            outerRadius="100%"
            fill={theme.colors.lightGrey}
            startAngle={90}
            endAngle={-270}
            paddingAngle={0}
            blendStroke
          >
            <Cell key="test" fill={theme.colors.primary} />
          </Pie>
        </PieChart>
        <GraphTextWrapper flex acenter column>
          <Text color="grey" fs={13}>
            Balance & Lifetime
          </Text>
          <Text fs={39}>11,580</Text>
          <Text fs={16}>16/24</Text>
        </GraphTextWrapper>
      </Box>
      <Box flex even>
        <Box>
          <Text color="grey" fs={13} lh={20}>
            Invested
          </Text>
          <Text fs={24} light lh={30}>
            €11,000
          </Text>
        </Box>
        <Box>
          <Text color="grey" fs={13} lh={20} pl={30}>
            Paid
          </Text>
          <Text pl={30} fs={24} light lh={30}>
            €1,000
          </Text>
        </Box>
      </Box>
    </CardWrapper>
  )
}
