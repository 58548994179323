import { Text } from "components/Text"
import { useMedia } from "hooks/useMedia"
import { Greeting } from "./Greeting"
import { MobileHeader } from "./MobileHeader"
import { Sidebar } from "./Sidebar"
import { PageWrapper, PageBody, Content, Inner } from "./styled"
import { UserIcons } from "./UserIcons"

export const Page = ({ withGreeting, title, children, customSidebar }) => {
  const { isDesktop } = useMedia()
  return (
    <PageWrapper flex={isDesktop}>
      {isDesktop ? (
        <Sidebar customSidebar={customSidebar} />
      ) : (
        <MobileHeader customSidebar={customSidebar} />
      )}
      <PageBody>
        <Content afterHeight={customSidebar ? 175 : 315}>
          <Inner>
            {isDesktop && <UserIcons />}
            {withGreeting && (
              <Greeting user={{ firstName: "John", lastName: "Doe" }} />
            )}
            <Text color="black" fs={25} light>
              {title}
            </Text>
            {children}
          </Inner>
        </Content>
      </PageBody>
    </PageWrapper>
  )
}
