import { Text } from "components/Text"
import { MobileProductItem } from "./MobileProductItem"

export const MobileProductList = () => (
  <>
    <Text fs={20} mb={20} lh={30}>
      Available Products
    </Text>
    <MobileProductItem />
    <MobileProductItem />
    <MobileProductItem />
    <MobileProductItem />
    <MobileProductItem />
    <MobileProductItem />
  </>
)
