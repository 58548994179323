import { Box } from "components/Box";
import { PageWithoutSidebar } from "components/PageWithoutSidebar";
import { useState, useCallback, useEffect } from "react";
import { Text } from "components/Text";
import { Portfolio } from "./Portfolio";
import { Withdrawals } from "./Withdrawals";
import { Fees } from "./Fees";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ErrorMessage from "components/ErrorMessage";
import { CircularProgress } from "@material-ui/core";
import {
  CircularProgressWrapper,
  LoadingWrapper,
  PortfolioWrapper,
} from "./Portfolio/styled";
import { Rebalances } from "./Rebalances";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const PortfolioPage = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fees, setFees] = useState(null)

  const loadData = useCallback(async () => {
    setIsLoading(true);

    const response = await axios
      .get(
        // API Chart & coins
        `https://show.hodlx.digital/v4/api/savings?hash=${query.get("hash")}&show_negative_bitcoin_values=no`
      )
      .catch((error) => {
        if (error.response) {
          setErrorMessage({
            data: error.response.data,
            status: error.response.status,
          });
        }
      });
      setData(response);

    if (!errorMessage) {
      setData(response);
      const feesData = response.data.events.fees
      if (feesData && Object.keys(feesData).length) {
        const valuesFees = Object.entries(feesData).map((record) => {
          return { date: record[0], value: record[1] }
        })
        setFees(valuesFees)
      }
    }

    setIsLoading(false);
  }, []);

  let query = useQuery();
  const user = query.get("hash");

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      {errorMessage ? (
        <ErrorMessage
          errorMessage={errorMessage.data.error}
          errorStatus={errorMessage.status}
        />
      ) : isLoading ? (
        <PortfolioWrapper>
          <LoadingWrapper>Loading...</LoadingWrapper>
          <CircularProgressWrapper>
            <CircularProgress color="inherit" size={150} />
          </CircularProgressWrapper>
        </PortfolioWrapper>
      ) : (
        <>
          <PageWithoutSidebar>
            <Box flex spread>
              <Box flex>
                <Text fs={20} mr={5}>
                  {user}'s savings:
                </Text>
              </Box>
              {/* <Button text="Calculate potential gain" fs={16} lh={20} /> */}
            </Box>
            <Portfolio response={data} />
            <Withdrawals response={data} />
            {fees && <Fees value={fees}/>}
            <Rebalances/>
          </PageWithoutSidebar>
        </>
      )}
    </>
  );
};
