import { Button } from "components/Button"
import { Text } from "components/Text"
import { useMedia } from "hooks/useMedia"
import { WarningWrapper } from "./styled"

export const Warning = () => {
  const { isMobile } = useMedia()
  return (
    <WarningWrapper p={20} pt={15} mb={30}>
      <Text fs={isMobile ? 13 : 20} lh={isMobile ? 20 : 30} bold mb={10}>
        Warning! Your monthly payment of €50
        {!isMobile && <br />}
        is approaching for the product: Backoffice / KB.
      </Text>
      <Button text="Show payment details" lh={20} />
    </WarningWrapper>
  )
}
