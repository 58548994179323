import { Page } from "components/Page"
import { Switch, Route } from "react-router-dom"
import { AffiliateSidebar } from "./AffiliateSidebar"
import * as R from "ramda"
import { affiliateSubRoutes } from "./subUrls"
import { urls } from "pages/urls"
import { AffNavigation } from "./AffNavigation"
import { Text } from "components/Text"

export const AffiliatePage = () => {
  return (
    <Page customSidebar={<AffiliateSidebar />}>
      <Text fs={25} lh={35} mb={15}>
        Affiliate Program
      </Text>
      <AffNavigation />
      <Switch>
        {R.values(affiliateSubRoutes(urls.affiliate)).map(url => (
          <Route
            key={url.path}
            component={url.component}
            exact={url.isExact}
            url={url.url}
            path={url.path}
          />
        ))}
      </Switch>
    </Page>
  )
}
