import { margins } from "common/styles"
import styled from "styled-components/macro"
import { theme } from "theme"

export const Divider = styled.div`
  width: 100%;
  position relative;
  ${margins};

  ::after {
    content: "";
    height: 1px;
    width: 100%;
    background: rgba(${theme.rgbColors.white}, 0.1);
    position: absolute;
  }
`
