import { SidebarWrapper } from "components/Page/Sidebar/styled"
import { Activity } from "./Activity"
import { AffiliateHeader } from "./AffiliateHeader"
import { Backoffice } from "./Backoffice"
export const AffiliateSidebar = () => (
  <SidebarWrapper p={30}>
    <AffiliateHeader />
    <Activity />
    <Backoffice />
  </SidebarWrapper>
)
