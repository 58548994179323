import { Box } from "components/Box"
import { Button } from "components/Button"
import { Text } from "components/Text"
import { ProductWrapper, ProductHeader } from "./styled"

export const MobileProductItem = () => (
  <ProductWrapper p={20} mb={10}>
    <ProductHeader flex spread pb={20} mb={20}>
      <Text fs={16} bold>
        Collateral
      </Text>
      <Text color="primary" bold underline>
        Read More
      </Text>
    </ProductHeader>
    <Box flex>
      <Text fs={16} lh={30} color="grey" mr={5}>
        Min. Investment:
      </Text>
      <Text fs={16} lh={30}>
        €16,000
      </Text>
    </Box>
    <Box flex mb={20}>
      <Text fs={16} lh={30} color="grey" mr={5}>
        Lifetime:
      </Text>
      <Text fs={16} lh={30}>
        2 years
      </Text>
    </Box>
    <Button text="Buy now" fullWidth fs={16} lh={20} bold />
  </ProductWrapper>
)
