import { ArrowBack } from "@material-ui/icons"
import { Box } from "components/Box"
import { Button } from "components/Button"
import { Icon } from "components/Icon"
import { Text } from "components/Text"
import { urls } from "pages/urls"
import { theme } from "theme"
import { Divider } from "../styled"

export const AffiliateHeader = () => (
  <>
    <Button
      fullWidth
      p={15}
      color="grey"
      bg={`rgba(${theme.rgbColors.white},0.1)`}
      to={urls.dashboard.url}
      text={
        <Box flex acenter>
          <Icon ml={17} mr={22} color="grey">
            <ArrowBack />
          </Icon>
          <Text lh={30}>Back</Text>
        </Box>
      }
    />
    <Box flex spread mt={20}>
      <Box flex>
        <Text mr={5}>Income</Text>
        <Text color="grey">(this month):</Text>
      </Box>
      <Box>
        <Text>€12,000</Text>
      </Box>
    </Box>
    <Box flex spread mt={10}>
      <Box flex>
        <Text mr={5}>New members</Text>
        <Text color="grey">(this month):</Text>
      </Box>
      <Box>
        <Text>15</Text>
      </Box>
    </Box>
    <Divider mt={20} />
  </>
)
