import styled from "styled-components/macro"
import { Box } from "components/Box"

export const ProductWrapper = styled(Box)`
  border-radius: 20px;
  border: 1px solid #ebeaea;
`

export const ProductHeader = styled(Box)`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #ebeaea;
  }
`
