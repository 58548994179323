import { AffFinance } from "./AffFinance"

export const affiliateSubRoutes = mainRoute => ({
  finance: {
    component: AffFinance,
    name: "Finance",
    path: `${mainRoute.path}/finance`,
    url: `${mainRoute.url}/finance`,
  },
  structure: {
    component: x => <div>structure</div>,
    name: "Structure",
    path: `${mainRoute.path}/structure`,
    url: `${mainRoute.url}/structure`,
  },
  transactions: {
    component: x => <div>transactions</div>,
    name: "Transactions",
    path: `${mainRoute.path}/transactions`,
    url: `${mainRoute.url}/transactions`,
  },
  career: {
    component: x => <div>career</div>,
    name: "Career",
    path: `${mainRoute.path}/career`,
    url: `${mainRoute.url}/career`,
  },
  materials: {
    component: x => <div>materials</div>,
    name: "Affiliate materials",
    path: `${mainRoute.path}/materials`,
    url: `${mainRoute.url}/materials`,
  },
  hodlx: {
    component: x => <div>hodlx</div>,
    name: "HODLX Digital",
    path: `${mainRoute.path}/hodlx`,
    url: `${mainRoute.url}/hodlx`,
  },
})
