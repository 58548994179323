import { Box } from "components/Box"
import { Text } from "components/Text"
import { AffiliateGraph } from "../AffiliateGraph"
import { Divider } from "../styled"

export const Activity = () => (
  <>
    <AffiliateGraph total={8} filled={1} />
    <Text fs={20} mt={20} mb={10} light tAlign="center">
      Active Member
    </Text>
    <Box flex spread mt={15}>
      <Box>
        <Text>Turnover</Text>
      </Box>
      <Box flex>
        <Text color="primary">€7,000</Text>
        <Text>/€12,000</Text>
      </Box>
    </Box>
    <Box flex spread mt={10}>
      <Box>
        <Text>Progress</Text>
      </Box>
      <Box flex>
        <Text>65%</Text>
      </Box>
    </Box>
    <Box flex spread mt={10}>
      <Box>
        <Text>Courses</Text>
      </Box>
      <Box flex>
        <Text color="primary">0</Text>
        <Text mr={5}>/2</Text>
        <Text>Courses</Text>
      </Box>
    </Box>

    <Divider mt={20} />
  </>
)
