import { Box } from "components/Box"
import styled from "styled-components/macro"
import { theme } from "theme"

export const PageWrapper = styled(Box)`
  height: 100vh;
`
export const PageBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: calc(100% - 80px);
  ${theme.mq.desktop} {
    border-radius: 50px 0 0 0;
    height: 100%;
  }
`
export const Content = styled.div`
  position: relative;
  background: ${theme.colors.white};
  z-index: 0;
  padding: 40px 20px 10px;
  height: 100%;
  overflow-y: auto;

  ::after {
    content: "";
    width: 100%;
    height: ${p => p.afterHeight}px;
    background: ${theme.colors.lighterGrey};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`

export const Inner = styled.div`
  max-width: 920px;
  margin: 0 auto;
  position: relative;
`
