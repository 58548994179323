import { urls } from "pages/urls"
import { affiliateSubRoutes } from "../subUrls"
import * as R from "ramda"
import { Link } from "components/Link"
import { AffNavWrapper } from "./styled"
import { Text } from "components/Text"

export const AffNavigation = () => (
  <AffNavWrapper flex spread>
    {R.values(affiliateSubRoutes(urls.affiliate)).map(item => {
      const active = window.location.pathname.includes(item.url)
      return (
        <Link to={item.url} key={item.name}>
          <Text
            lh={30}
            fs={20}
            color={active ? "black" : "grey"}
            pt={25}
            pb={30}
          >
            {item.name}
          </Text>
        </Link>
      )
    })}
  </AffNavWrapper>
)
