import { Box } from "components/Box"
import styled, { css } from "styled-components/macro"
import { theme } from "theme"

export const AffNavWrapper = styled(Box)`
  a {
    ${p =>
      p.active &&
      css`
        color: ${theme.colors.black};
      `}
  }
`
